import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/html5-introduction",
  "date": "2014-10-23",
  "title": "HTML5 INTRODUCTION",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "HTML5 is the latest HTML standard. It walks hand in hand with CSS3, the latest CSS standard."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`HTML5 includes:`}</p>
    <ul>
      <li parentName="ul">{`New HTML elements and attributes`}</li>
      <li parentName="ul">{`Full CSS3 Support`}</li>
      <li parentName="ul">{`Video and audio elements`}</li>
      <li parentName="ul">{`2D/3D graphics`}</li>
      <li parentName="ul">{`Local storage`}</li>
      <li parentName="ul">{`Local SQL database`}</li>
    </ul>
    <h2>{`What is New in HTML5?`}</h2>
    <p>{`HTML5 has scene new updated elements:`}</p>
    <ul>
      <li parentName="ul">{`Doctype`}</li>
      <li parentName="ul">{`Meta declaration`}</li>
      <li parentName="ul">{`Script tag`}</li>
      <li parentName="ul">{`Link tag`}</li>
    </ul>
    <h2>{`DOCTYPE`}</h2>
    <p>{`The DOCTYPE declaration for HTML5 is very simple. But let's look at the doctypes before HTML5:`}</p>
    <p>{`HTML 4.01 Strict`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
`}</code></pre>
    <p>{`HTML 4.01 Transitional`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
`}</code></pre>
    <p>{`HTML 4.01 Frameset`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Frameset//EN" "http://www.w3.org/TR/html4/frameset.dtd">
`}</code></pre>
    <p>{`The new HTML5 doctype:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
`}</code></pre>
    <h2>{`META DECLARATION`}</h2>
    <p>{`The meta declaration in HTML4:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
`}</code></pre>
    <p>{`In HTML5, this has been simplified:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<meta charset="UTF-8">
`}</code></pre>
    <h2>{`SCRIPT TAG`}</h2>
    <p>{`In HTML 4.01, we specify the type attribute as text/javascript:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script type="text/javascript" src="file.js"></script>
`}</code></pre>
    <p>{`In HTML5, the type attribute is not needed, as the browser will infer javascript as the type:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script src="file.js"></script>
`}</code></pre>
    <h2>{`LINK TAG`}</h2>
    <p>{`The link tag in HTML 4.01:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<link rel="stylesheet" type="text/css" href="file.css">
`}</code></pre>
    <p>{`Again, in HTML5, we don't need the type attribute on the link tag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<link rel="stylesheet" href="file.css">
`}</code></pre>
    <h2>{`EXISTING HTML5 TAG UPDATES`}</h2>
    <p>{`In HTML4, the i and b tags were font style elements:`}</p>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`i`}</strong>{` tag rendered an italic font style`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`b`}</strong>{` tag rendered a bold font style`}</li>
    </ul>
    <p>{`In HTML5, these tags have new meanings:`}</p>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`i`}</strong>{` tag represents text in an `}<strong parentName="li">{`alternate voice`}</strong>{` or `}<strong parentName="li">{`mood`}</strong></li>
      <li parentName="ul">{`The `}<strong parentName="li">{`b`}</strong>{` tag represents `}<strong parentName="li">{`stylistically offset`}</strong>{` text`}</li>
    </ul>
    <h2>{`EXISTING HTML5 TAG UPDATES`}</h2>
    <p>{`Some example uses for the `}<strong parentName="p">{`i`}</strong>{` tag:`}</p>
    <ul>
      <li parentName="ul">{`Taxonomic designation`}</li>
      <li parentName="ul">{`Technical term`}</li>
      <li parentName="ul">{`Idiomatic phrase from another language`}</li>
      <li parentName="ul">{`Transliteration`}</li>
      <li parentName="ul">{`A thought`}</li>
      <li parentName="ul">{`Ship name in Western texts`}</li>
    </ul>
    <p>{`Example usage of the i tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<i>I hope this works</i>, he thought.
`}</code></pre>
    <p>{`Here the words in side `}<strong parentName="p">{`i`}</strong>{` tags depicting a text in an `}<strong parentName="p">{`alternative voice`}</strong>{` or `}<strong parentName="p">{`mood`}</strong>{`.`}</p>
    <p>{`Some example uses for the b tag:`}</p>
    <ul>
      <li parentName="ul">{`Key words in a document abstract`}</li>
      <li parentName="ul">{`Product names in a review`}</li>
      <li parentName="ul">{`Actionable words in interactive text-driven software`}</li>
      <li parentName="ul">{`Article lead`}</li>
    </ul>
    <p>{`Example usage of the b tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<b class="lead">The event takes place this upcoming Saturday, and
over 3,000 people have already registered.**
`}</code></pre>
    <p>{`This article lead is `}<strong parentName="p">{`stylistically offset`}</strong>{`.`}</p>
    <p>{`In HTML4:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`<em>`}</inlineCode>{` tag meant emphasis`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`<strong>`}</inlineCode>{` tag meant strong emphasis`}</li>
    </ul>
    <p>{`But in HTML5, the em and strong tags have new meanings:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`<em>`}</inlineCode>{` tag now means `}<strong parentName="li">{`stress emphasis`}</strong></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`<strong>`}</inlineCode>{` tag now means `}<strong parentName="li">{`strong importance`}</strong></li>
    </ul>
    <p>{`Example usage of the em tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`Make sure to sign up \`before\` the day of the event, September
16, 2013.
`}</code></pre>
    <p>{`Here we're giving `}<strong parentName="p">{`stress emphasis`}</strong>{` to `}<strong parentName="p">{`before`}</strong>{`.`}</p>
    <p>{`Example usage of the `}<inlineCode parentName="p">{`<strong>`}</inlineCode>{` tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`Make sure to sign up <em>before</em> the day of the event,
<strong>September 16, 2013</strong>".
`}</code></pre>
    <p>{`Here we're giving `}<strong parentName="p">{`strong importance`}</strong>{` to the date of the event.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      